import React from 'react'
import "../App.css";
// import Navbar from './Navbar';
import Homesection from './HomeSection';
import Project from './Project';
// import HomelandRegallia from './HomelanRegallia';
// import Footer from './Footer';
export default function Home(params)  {
    return(
        <>
       
        {/* <Navbar/> */}
        <Homesection/>
       
        {/* <Footer/> */}
  </>
  );
}
