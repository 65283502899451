import React, { Component } from 'react'
import { useEffect } from 'react';
import "../CSS/HeroProject.css"
import "../App.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function AboutTcr() {

    useEffect(() => {
        AOS.init({
            duration: 2000, // Animation duration in milliseconds
            once: true,     // Whether animation should happen only once
        });
    }, []);
    return (
        <>
            <div className="about-container">
                <div className="project-heading">
                    <h3>about</h3>
                </div>
                <div className="about-item">
                    <div className="about-img" data-aos='fade-right'>
                        <img src={require('../images/tcrabout.jpeg')} alt="" />
                    </div>
                    <div className="about-text" data-aos='fade-left'>
                        <div>
                            <h4>FOUNDER TCR</h4>
                        </div>
                        <div>
                            <p>THE CHANDIGARH REALTORS brings the TCR Group philosophy of innovation, sustainability, and excellence to the real estate industry. Each TCR Properties development combines a 10–year legacy of excellence and trust with a commitment to cutting-edge design and technology.
                                <br />The Chandigarh Realtors comprises of a varied business portfolio that includes real estate development, fast moving consumer goods, advanced engineering, home appliances, furniture, security, and agri-care.
                                <br />Our mission is simple yet profound: to transform the real estate journey into a seamless, empowering, and rewarding experience for every client. We strive to go beyond the conventional and bring innovation to every interaction, fostering an environment of trust and reliability.
                                <br />We understand that real estate decisions are not just financial—they're deeply personal. That's why we take the time to listen, understand your goals, and tailor our services to suit your unique requirements. From scouting the perfect property to navigating complex negotiations, we're here to ensure that your real estate journey is smooth, informed, and successful.
                                <br />Join us on this journey and experience the difference that THE CHANDIGARH REALTORS can make in your real estate endeavors. Reach out to us today, and let's turn your dreams into reality.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

