import React, {useEffect} from 'react'
import "../App.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
// import herohomes from "../images/hero_homes1.jpeg"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export default function Project() {
  useEffect(() => {
    AOS.init({
      duration: 2000, // Animation duration in milliseconds
      once: true,     // Whether animation should happen only once
    });
  }, []);

  return (
    <>
      <div className="project-home">
        <div className="project-heading" data-aos='fade-up'>
          <h3 >our Projects</h3>
          <hr />
        </div>
        {/* <div className="project-first"> */}
          <div className="hero-homes" data-aos='fade-up'>
          <Link to="/heroproject" className='link-project'>
            <div className="first-project" >
            
              <img src={require('../images/hero-homes/03.jpg')} alt="" />
              <p className='project-name'>HERO HOMES</p>
              <p className='project-address'><FontAwesomeIcon icon="fa-solid fa-location-dot" /> sector 88,mohali
              </p>  
              <button className="more-btn">more detail </button>
              {/* <a href="/" className="more-btn">more detail</a> */}
                       </div></Link> 
            
            <Link to="/homelandregallia" className='link-project'>
            <div className="first-project">
              <img src={require('../images/homeland-regalia/1.jpg')} alt="" />
              <p className='project-name'>HOMELAND REGALLIA</p>
              <p className='project-address'><FontAwesomeIcon icon="fa-solid fa-location-dot" /> sector 77,mohali
              </p>       
              <button className="more-btn">more detail </button>     </div></Link>
            <Link to="/medallionproject" className='link-project'>
            <div className="first-project">
              <img src={require('../images/the-medallion/medallion_glimpse1-1.jpg')} alt="" />
              <p className='project-name'>THE MEDALLION</p>
              <p className='project-address'><FontAwesomeIcon icon="fa-solid fa-location-dot" /> sector 82,mohali
              </p>        <button className="more-btn">more detail </button> 
                 </div></Link>
          </div>
          <div className="hero-homes" data-aos='fade-up'>
          <Link to="/medallionauram" className='link-project'>
            <div className="first-project">
              <img src={require('../images/medallion-auram/7.jpg')} alt="" />
              <p className='project-name'>Medallion Auram</p>
              <p className='project-address'><FontAwesomeIcon icon="fa-solid fa-location-dot" /> sector 67,mohali
              </p>       
              <button className="more-btn">more detail </button>     </div></Link>
            <Link to="/noblewillasa" className='link-project'>
            <div className="first-project">
              <img src={require('../images/noble-willasa/17.jpeg')} alt="" />
              <p className='project-name'>Nobel Willasa</p>
              <p className='project-address'><FontAwesomeIcon icon="fa-solid fa-location-dot" /> sector 99,mohali
              </p>       
              <button className="more-btn">more detail </button>     </div></Link>
            <Link to="/noblecallista" className='link-project'>
            <div className="first-project">
              <img src={require('../images/noble-callista/NOBEL-AIRIAL-FINAL.jpg')} alt="" />
              <p className='project-name'>Nobel Callista</p>
                <p className='project-address'><FontAwesomeIcon icon="fa-solid fa-location-dot" /> sector 66B,mohali
              </p>
              <button className="more-btn">more detail </button>
              {/* <p><FontAwesomeIcon icon="fa-solid fa-location-dot" />SECTOR 88 MOHALI</p> */}
            </div></Link>
          </div>
          <div className="hero-homes" data-aos='fade-up'>
          <Link to="/horizonbelmond" className='link-project'>
            <div className="first-project">
              <img src={require('../images/horizon-belmond/belmond.jpg')} alt="" />
              <p className='project-name'>Horizon Belmond</p>
              <p className='project-address'><FontAwesomeIcon icon="fa-solid fa-location-dot" /> sector 88,mohali
              </p>        <button className="more-btn">more detail </button>    
              </div>
              </Link>
            <Link to="/falconview" className='link-project'>
            <div className="first-project">
              <img src={require('../images/falcon-view/b1.jpg')} alt="" />
              <p className='project-name'>jlpl FAlcon view</p>
              <p className='project-address'><FontAwesomeIcon icon="fa-solid fa-location-dot" /> sector 66,mohali
              </p>     <button className="more-btn">more detail </button>       
              </div>
              </Link>
            <Link to="/ambicala" className='link-project'>
            <div className="first-project">
              <img src={require('../images/noble-callista/NOBEL-AIRIAL-FINAL.jpg')} alt="" />
              <p className='project-name'>ambika la parisian</p>
                <p className='project-address'><FontAwesomeIcon icon="fa-solid fa-location-dot" /> sector 66B,mohali
              </p><button className="more-btn">more detail </button>
              {/* <p><FontAwesomeIcon icon="fa-solid fa-location-dot" />SECTOR 88 MOHALI</p> */}
            </div>
            </Link>
          </div>
        {/* </div> */}
      </div>
    </>
  )
}
