import React from 'react'
import 'lightgallery/css/lightgallery.css'; 
import LightGallery from 'lightgallery/react/Lightgallery.es5'
import "../App.css";
import "../CSS/HeroProject.css"
// import IMAGES from './IMAGES';
function HomelandRegallia() {
    return (
        <>
            <div className='project-heading-regallia'>
                <p className='hero-heading home-regallia'>homeland regallia<br />sector 77,mohali </p>
            </div>
            <div className="hero-navbar">
                <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
                    <div className="container-fluid">
                        {/* <a className="navbar-brand" href="/">Overview</a> */}
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">OVERVIEW</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">FLOOR-PLAN</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="/">Gallery</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="/">Amenities</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">Location</a>
                                </li>


                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="overview">
                <div className="overview-heading">
                    <h3>overview</h3>
                </div>
                {/* <div className="about-auram"> */}

                    <div className="description ">
                        <p><span>PROJECT HIGHLIGHTS</span><br />

                            Homeland Regalia Flats
                            Homeland Regalia offers luxury flats for sale in Mohali Sector 77. These flats are intelligently designed and assured to have no wasted space. The apartments have been designed for your convenience, comfort, and safety. Each of them is designed with an ingenious blend of quality fixtures, fittings, and finishing. Over 4 acres and 20,000 square feet of space are available. These high-rise luxury apartments are available in 3, 4 and 5 BHK options. You can easily choose the one which is the most convenient and best suits your needs The project also has numerous world-class facilities for sports and recreation lined up for you. It doesn't get much better than this!.</p>
                    </div>
                    {/* <div className="about-img regalia-img">
                        <img src={require('../images/homeland-regalia/3-bhk.jpg')} alt="" />
                    </div> */}

                {/* </div> */}
            </div>

                {/* floor plan section start  */}
            {/* <div className="floor-plan">
                <div className="overview-heading">
                    <h3>floor-plan</h3>
                </div>
                <div className="container-floor-auram">
                    <div className="first-plan-auram">
                        
                           
                        <div>
                        <LightGallery>
                        <a href={require('../images/medallion-auram/f2.jpg')}>
                            <img src={require('../images/medallion-auram/f2.jpg')} alt="" />
                            </a>
                            </LightGallery>
                        </div>
                       
                        
                        <div>
                        <LightGallery>
                        <a href={require('../images/medallion-auram/f4.jpg')}>
                            <img src={require('../images/medallion-auram/f4.jpg')} alt="" />
                            </a>
                            </LightGallery>
                        </div>
                        
                        
                    </div>
                </div>
            </div> */}
            {/* floor plan section end  */}

            {/* gallery section start  */}
            <div className="gallery-section">
                <div className="overview-heading">
                    <h3>gallery</h3>
                </div>
                <div className="gallery-plan">
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/homeland-regalia/1.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/homeland-regalia/2.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/homeland-regalia/3.jpg')} alt="" />
                        </div>

                    </div>
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/homeland-regalia/4.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/homeland-regalia/5.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/homeland-regalia/6.jpg')} alt="" />
                        </div>

                    </div>
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/homeland-regalia/7.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/homeland-regalia/8.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/homeland-regalia/9.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/homeland-regalia/10.jpg')} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* gallery section end  */}

            {/* amenities section start  */}

            {/* amenities section end  */}
            <div className="location-section">
                <div className="overview-heading">
                    <h3>location</h3>
                    <div className="hero-location">
                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3431.088005326632!2d76.69062437465959!3d30.687799887804466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feede075e73a7%3A0x4ea36ee86d384390!2sHero%20Homes%2C%20HERO%20HOMES%2C%20Site%20no.1%2C%20Sector%2088%2C%20Sahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab%20140308!5e0!3m2!1sen!2sin!4v1698322349250!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    </div>
                </div>

            </div>
        </>
    )
}

export default HomelandRegallia
