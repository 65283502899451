import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Project from './components/Project';
import HeroProject from './components/HeroProject';
import MedallionProject from './components/MedallionProject';
import MedallionAuram from './components/MedallionAuram';
import Home from './components/Home';
import HomelandRegallia from './components/HomelanRegallia';
import React from "react";
import { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes
} from "react-router-dom";
import NobleWillasa from './components/NobleWillasa';
import ContactTcr from './components/ContactTcr';
import NobleCallista from './components/NobleCallista';
import HorizonBelmond from './components/HorizonBelmond';
import FalconView from './components/FalconView';
import AmbicaLa from './components/AmbicaLa';
import AboutTcr from './components/AboutTcr';
import PopForm from './components/PopForm';
import LocomotiveScroll from 'locomotive-scroll';

function App() {
  const locomotiveScroll = new LocomotiveScroll();
  const[buttonPopUp,setbuttonPopUp] = useState(false);
  const[timePopUp,setTimePopUp] = useState(false);
  setTimeout(() => {
    setTimePopUp(true);
  }, 10000);
  return (
    <>
   {/* <Route path="/about">

            <About />
          </Route> */}
     <Router>
    
    <Navbar />
    {/* <PopForm trigger={timePopUp} setTrigger={setTimePopUp} /> */}
    <Routes>
          
          
          <Route path="/Project" element={<Project />}>
          </Route>
          <Route path="/AmbicaLa" element={<AmbicaLa />}>
          </Route>
          <Route path="/FalconView" element={<FalconView />}>
          </Route>
          <Route path="/HorizonBelmond" element={<HorizonBelmond />}>
          </Route>
          <Route path="/Contacttcr" element={<ContactTcr />}>
          </Route>
          <Route path="/NobleCallista" element={<NobleCallista />}>
          </Route>
          <Route path="/HeroProject" element={<HeroProject />}>
          </Route>
          <Route path="/HomelandRegallia" element={<HomelandRegallia />}>
          </Route>
          <Route path="/MedallionAuram" element={<MedallionAuram />}>
          </Route>
          <Route path="/MedallionProject" element={<MedallionProject />}>
          </Route>
          <Route path="/" element={<Home />}>
          </Route>
          <Route path="/NobleWillasa" element={<NobleWillasa />}>
          </Route>
          <Route path="/AboutTcr" element={<AboutTcr />}>
          </Route>
        </Routes>
    <Footer />
    </Router>
    </>
  );
}

export default App;
