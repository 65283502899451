import React from 'react'
import 'lightgallery/css/lightgallery.css'; 
import LightGallery from 'lightgallery/react/Lightgallery.es5'
import "../App.css";
import "../CSS/HeroProject.css"
// import IMAGES from './IMAGES';
function HeroProject() {
  return (
    <>
      <div className='project-heading-hero'>
        <p className='hero-heading'>Hero Homes <br></br>The HEART OF MOHALI</p>
      </div>
      <div className="hero-navbar">
        <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
          <div className="container-fluid">
            {/* <a className="navbar-brand" href="/">Overview</a> */}
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/">OVERVIEW</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/">FLOOR-PLAN</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" href="/">Gallery</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" href="/">Amenities</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/">Location</a>
                </li>


              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className="overview">
        <div className="overview-heading">
          <h3>overview</h3>
        </div>
        <div className="description">
          <p>Hero Homes Mohali Phase-2 is a developing/developed project based on our 4 design pillars of the brand philosophy – Fitness | Sustainability | Community | Creativity

            These four parameters help in adding distinctive features to the homes that lead to an enriched lifestyle for you. The company of loved ones set amidst beautiful surroundings bespoke a lifestyle of pure bliss. At Hero Homes Mohali, life is all about the fine things that make living worthwhile. Because a house is not enough, it needs to be changed into a comfortable haven with delightful amenities, open green spaces, play areas for children, a good neighbourhood and above all, a location that serves as an oasis of peace in the chaos of city life. All these and more await you at Hero Homes for a complete life of bliss.


            RERA Registration No. : PBRERA-SAS81-PR0522
            Contact No: +91-8950801205
            Email: herohomesmohali01@gmail.com</p>
        </div>
      </div>


      
      <div className="floor-plan">
        <div className="overview-heading">
          <h3>floor-plan</h3>
        </div>
        
        <div className="container-floor">
          <div className="first-plan">
          
          
            <div>
            <LightGallery >
            <a href={require('../images/hero-homes/floor-plan.jpeg')}>
              <img src={require('../images/hero-homes/floor-plan.jpeg')} alt="" className='img-floor'/>
              </a>
              </LightGallery>
              
            </div>
            <div>
            <LightGallery >
            <a href={require('../images/hero-homes/floor-plan4.jpeg')}>
              <img src={require('../images/hero-homes/floor-plan4.jpeg')} alt="" className='img-floor'/>
              </a>
              </LightGallery>
            </div>
            
           
          </div>
          <div className="first-plan">
          <LightGallery >
            <a href={require('../images/hero-homes/floor-plan2.jpeg')}>
              <img src={require('../images/hero-homes/floor-plan2.jpeg')} alt="" className='img-floor'/>
              </a>
              </LightGallery>
              <LightGallery >
            <a href={require('../images/hero-homes/floor-plan6.jpeg')}>
              <img src={require('../images/hero-homes/floor-plan6.jpeg')} alt="" className='img-floor'/>
              </a>
              </LightGallery>
          </div>
          <div className="first-plan">
          <LightGallery >
            <a href={require('../images/hero-homes/a05.jpg')}>
              <img src={require('../images/hero-homes/a05.jpg')} alt="" className='img-floor'/>
              </a>
              </LightGallery>
          </div>


        </div>
        
      </div>
      {/* floor plan section end  */}

      {/* gallery section start  */}
      <div className="gallery-section">
        <div className="overview-heading">
          <h3>gallery</h3>
        </div>
        <div className="gallery-plan">
          <div className="hero-gallery">
            <div>
              <img src={require('../images/hero-homes/1.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/hero-homes/2.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/hero-homes/3.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/hero-homes/4.jpg')} alt="" />
            </div>
          </div>
          <div className="hero-gallery">
            <div>
              <img src={require('../images/hero-homes/5.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/hero-homes/6.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/hero-homes/7.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/hero-homes/8.jpg')} alt="" />
            </div>
          </div>
          <div className="hero-gallery">
            <div>
              <img src={require('../images/hero-homes/9.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/hero-homes/10.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/hero-homes/11.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/hero-homes/12.jpg')} alt="" />
            </div>
          </div>
          <div className="hero-gallery">
            <div>
              <img src={require('../images/hero-homes/13.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/hero-homes/14.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/hero-homes/15.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/hero-homes/16.jpg')} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* gallery section end  */}

      {/* amenities section start  */}
      <div className="amenities-section">
        <div className="overview-heading">
          <h3>amenities </h3>
        </div>
        <div className="amenities-plan">
          <div className="hero-amenities">
            <div>
              <img src={require('../images/hero-homes/Amphitheater.jpg')} alt="" />
              <p>Amphitheater</p>
            </div>
            <div>
              <img src={require('../images/hero-homes/barbeque-patio-party-lawn.jpg')} alt="" />
              <p>barbeque-patio-partylawn</p>
            </div>
            <div>
              <img src={require('../images/hero-homes/basketball-court.jpg')} alt="" />
              <p>basketball-court</p>
            </div>
            <div>
              <img src={require('../images/hero-homes/chess-garden.jpg')} alt="" />
              <p>chess-garden</p>
            </div>
            <div>
              <img src={require('../images/hero-homes/community-garden.jpg')} alt="" />
              <p>community-garden</p>
            </div>
          </div>
          <div className="hero-amenities">
            <div>
              <img src={require('../images/hero-homes/cricket-pitch-size.jpg')} alt="" />
              <p>cricket-pitch</p>
            </div>
            <div>
              <img src={require('../images/hero-homes/cycle-track.jpg')} alt="" />
              <p>cycle-track</p>
            </div>
            <div>
              <img src={require('../images/hero-homes/elderlypark.jpg')} alt="" />
              <p>elderlypark</p>
            </div>
            <div>
              <img src={require('../images/hero-homes/fitness-lawn.jpg')} alt="" />
              <p>fitness-lawn</p>
            </div>
            <div>
              <img src={require('../images/hero-homes/healing-garden.jpg')} alt="" />
              <p>healing-garden</p>
            </div>
          </div>
          <div className="hero-amenities">
            <div>
              <img src={require('../images/hero-homes/jogging-track.jpg')} alt="" />
              <p>jogging-track</p>
            </div>
            <div>
              <img src={require('../images/hero-homes/KidsPlayzone3.jpg')} alt="" />
              <p>KidsPlayzone</p>
            </div>
            <div>
              <img src={require('../images/hero-homes/lawn-tennis-court.jpg')} alt="" />
              <p>lawn-tenniscourt</p>
            </div>
            <div>
              <img src={require('../images/hero-homes/meditation-area.jpg')} alt="" />
              <p>meditation-area</p>
            </div>
            <div>
              <img src={require('../images/hero-homes/open-dinning.jpg')} alt="" />
              <p>open-dinning</p>
            </div>
          </div>
          <div className="hero-amenities">
            <div>
              <img src={require('../images/hero-homes/Reading-Lounge.jpg')} alt="" />
              <p>Reading-Lounge</p>
            </div>
            <div>
              <img src={require('../images/hero-homes/skating-rink.jpg')} alt="" />
              <p>skating-rink</p>
            </div>
            <div>
              <img src={require('../images/hero-homes/spa&sauna.jpg')} alt="" />
              <p>spa&sauna</p>
            </div>
            <div>
              <img src={require('../images/hero-homes/sport-squash-court.jpg')} alt="" />
              <p>sport-squash-court</p>
            </div>
            <div>
              <img src={require('../images/hero-homes/themed-garden.jpg')} alt="" />
              <p>multiple themed-garden</p>
            </div>
          </div>
          <div className="hero-amenities">
            <div>
              <img src={require('../images/hero-homes/yoga.jpg')} alt="" />
              <p>yoga area</p>
            </div>
          </div>
        </div>
      </div>

      {/* amenities section end  */}
      <div className="location-section">
        <div className="overview-heading">
          <h3>location</h3>
          <div className="hero-location">
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3431.088005326632!2d76.69062437465959!3d30.687799887804466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feede075e73a7%3A0x4ea36ee86d384390!2sHero%20Homes%2C%20HERO%20HOMES%2C%20Site%20no.1%2C%20Sector%2088%2C%20Sahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab%20140308!5e0!3m2!1sen!2sin!4v1698322349250!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
          </div>
        </div>

      </div>
    </>
  )
}

export default HeroProject
