import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
// import { faEnvelope } from '@fortawesome/free-solid-svg'
function Footer() {
  return (
    <>
      <div className='footer'>
        <div className='footer-logo'>
          <p>THE chandigarh realtors</p>
        </div>
        {/* <div className="footer-link">
        <p>Quick Link</p>
        <div className="social-link">
        <a href="/"><FontAwesomeIcon icon="fa-brands fa-facebook" /></a>
        <a href="/"><FontAwesomeIcon icon="fa-brands fa-instagram" /></a>
        <a href="https://www.youtube.com/results?search_query=property+tuber+amit" target='blank'><FontAwesomeIcon icon="fa-brands fa-youtube" /></a>
        <a href="/"><FontAwesomeIcon icon="fa-brands fa-twitter" /></a>
        </div>
      </div>
      <div className='footer-contact'>
        <div>       
        <p><FontAwesomeIcon icon="fa-solid fa-location-dot" /> Showroom 53 ,1st floor,centrel plaza market , sector 105 EMaar</p>
        <p><FontAwesomeIcon icon="fa-solid fa-phone" /> +91 8950801205</p>
        <p><FontAwesomeIcon icon="fa-regular fa-envelope" /> propertytuber@gmail.com</p>
      </div>

      </div>
       */}
        <div className="footer-link">
          <div className="footer-about">
            <Link to="/">Home</Link>
            <Link to="/project">Project</Link>
            <Link to="/abouttcr">About</Link>
            <Link to="/contacttcr">Contact US</Link>
          </div>
          <div className="social-link">

             <a href="https://www.facebook.com/profile.php?id=100009797590879"><FontAwesomeIcon icon="fa-brands fa-facebook" /></a>
            <a href="/"><FontAwesomeIcon icon="fa-brands fa-instagram" /></a>
            <a href="https://www.youtube.com/results?search_query=property+tuber+amit" target='blank'><FontAwesomeIcon icon="fa-brands fa-youtube" /></a>
            <a href="/"><FontAwesomeIcon icon="fa-brands fa-twitter" /></a>
          </div>
        </div>
        <div className="footer-address">
          <div className="address">
            <p><FontAwesomeIcon icon="fa-solid fa-location-dot" /> Showroom 53 ,1st floor,centrel plaza market , sector 105 EMaar,Mohali</p>
          </div>
          <div className="contact">
            <p><FontAwesomeIcon icon="fa-solid fa-phone" /> +91 8950801205</p>
            <p><FontAwesomeIcon icon="fa-regular fa-envelope" /> thechandigarhrealtor@gmail.com</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
