import React from 'react'
import '../App.css';
import { Link } from 'react-router-dom';
export default function Navbar(params) {
  return (
    <>
      {/*  */}
      <nav className="navbar navbar-expand-lg navbar-dark  fixed-top" role="navigation">
        {/* <div className="logo-nav"> */}

        <div className="nav-logo">
          <div className="logo"></div>
        </div>
        <div className='tcr-nav'>
          <div className="container-fluid">
            {/* <img src={require("../images/tcr-logo.jpeg")} className='logo' alt="" /> */}
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse " id="navbarNav" >
              <ul className="navbar-nav navul">
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/project">Project</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/abouttcr">About</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contacttcr">Contact</Link>
                </li>

              </ul>
            </div>
          </div>
        </div>
        {/* </div> */}
      </nav>
      {/* <nav>

          <ul className='navul'>
            <a href="/"><li>home</li></a>
            <a href="/"><li>our project</li></a>
            <a href="/"><li>about</li></a>
            <a href="/"><li>upcoming project</li></a>

          </ul>
        </nav> */}


    </>
  )
}
