import React, { useEffect } from 'react'
import "../App.css";
import "../CSS/HeroProject.css"
import Project from './Project';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function HomeSection() {
    
    useEffect(() => {
        AOS.init({
          duration: 2000, // Animation duration in milliseconds
          once: true,     // Whether animation should happen only once
        });
      }, []);
    return (
        <>
            <div className="home-section">
                
                <div className='home-text'>
                    <h1 data-aos="fade-up">
                        your home waiting for you..
                    </h1>
                    {/* <div className="search-item">
                    <form class="d-flex home-search" role="search">
        
      </form>
                    </div> */}
                                </div>
                <div className='form' data-aos="fade-up">
                    <form action="" className='home-form'>
                        <h3>GET IN TOUCH </h3>
                        <input type="text" name="" id="" placeholder='Enter Your Name' className='box1'/><br />
                        <input type='number' placeholder='Enter Your Number' className='box1'/><br />
                        <input class="form-control me-2 box1" type="search" placeholder="Enter Your Query " aria-label="Search" />
                        <button class="btn btn-outline-success  btn" type="submit">Submit</button>
                        {/* <button type='submit' className='btn'>Submit</button> */}
                    </form>
                </div>
                
             
            </div>
            <Project />
            
            <section className="youtube-section">
                <div className="project-heading">
                    <h3>our videos</h3>
                    <hr />
                </div>
                <div className="container-youtube">
                        <div className="first" data-aos="fade-right">
                        <iframe src="https://www.youtube.com/embed/Y5h2G-6Nrgo?si=a5xD31__ra2eChxn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                    
                        </div>
                        <div className="first" data-aos="fade-left">
                        <iframe src="https://www.youtube.com/embed/igpi-VLGTyc?si=QlHi4bggeDAGGmDh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                         </div>
                 </div>
                 <div className="container-youtube">
                         <div className="first"data-aos="fade-right">
                         <iframe src="https://www.youtube.com/embed/tDVBkZuFdq4?si=s67JfSQiPwYOlls7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                        </div>
                        <div className="first"data-aos="fade-left">
                        <iframe src="https://www.youtube.com/embed/JC1CUOJu-tg?si=uku1voAS2lqnVkrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                        </div> 
                </div>
                <div className="container-youtube">
                         <div className="first"data-aos="fade-right">
                         <iframe src="https://www.youtube.com/embed/XjkbnUpfTCA?si=gums-2x6LowIDvob" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                        </div>
                        <div className="first"data-aos="fade-left">
                        <iframe src="https://www.youtube.com/embed/DSYBRWZETvA?si=EGsLuXN84ovWKUGc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                        </div> 
                </div>
                
                
            </section>
            
        </>
    )
}

//   {/* <div className="slider">
//                 <div className='home'>
//                                 <div className='home-text'>
//                                     <h1>
//                                         your home waiting for you..
//                                     </h1>
//                                 </div>
//                                 <div className='form'>
//                                     <form action="" className='home-form'>
//                                         <h3>log in </h3>
//                                         <input type="text" name="" id="" placeholder='enter your name' className='box1' /><br />
//                                         <input type='number' placeholder='enter your number' className='box1' /><br />
//                                         <button type='submit' className='box1'>Submit</button>
//                                     </form>
//                                 </div>

//                             </div>
//                     <figure>
//                         <div className="slide">
                                                      
//                               <img src={require('../CSS/image/bg1.jpg')} alt="/" />
//                         </div>
//                     </figure>
//                     <figure>
//                         <div className="slide">
//                             <div className='home'>
//                                 <div className='home-text'>
//                                     <h1>
//                                         your home waiting for you..
//                                     </h1>
//                                 </div>
//                                 <div className='form'>
//                                     <form action="" className='home-form'>
//                                         <h3>log in </h3>
//                                         <input type="text" name="" id="" placeholder='enter your name' className='box1' /><br />
//                                         <input type='number' placeholder='enter your number' className='box1' /><br />
//                                         <button type='submit' className='box1'>Submit</button>
//                                     </form>
//                                 </div>

//                             </div>
//                             <img src={require('../CSS/image/bg2.jpg')} alt="/" />
//                         </div>
//                     </figure>
//                     <figure>
//                         <div className="slide">
//                             <div className='home'>
//                                 <div className='home-text'>
//                                     <h1>
//                                         your home waiting for you..
//                                     </h1>
//                                 </div>
//                                 <div className='form'>
//                                     <form action="" className='home-form'>
//                                         <h3>log in </h3>
//                                         <input type="text" name="" id="" placeholder='enter your name' className='box1' /><br />
//                                         <input type='number' placeholder='enter your number' className='box1' /><br />
//                                         <button type='submit' className='box1'>Submit</button>
//                                     </form>
//                                 </div>

//                             </div>
//                             <img src={require('../CSS/image/bg3.jpg')} alt="/" />
//                         </div>
//                     </figure>
//                     <figure>
//                         <div className="slide">
//                             <div className='home'>
//                                 <div className='home-text'>
//                                     <h1>
//                                         your home waiting for you..
//                                     </h1>
//                                 </div>
//                                 <div className='form'>
//                                     <form action="" className='home-form'>
//                                         <h3>log in </h3>
//                                         <input type="text" name="" id="" placeholder='enter your name' className='box1' /><br />
//                                         <input type='number' placeholder='enter your number' className='box1' /><br />
//                                         <button type='submit' className='box1'>Submit</button>
//                                     </form>
//                                 </div>

//                             </div>
//                             <img src={require('../CSS/image/bg4.jpg')} alt="/" />
//                         </div>
//                     </figure>
//                     <figure>
//                         <div className="slide">
//                             <div className='home'>
//                                 <div className='home-text'>
//                                     <h1>
//                                         your home waiting for you..
//                                     </h1>
//                                 </div>
//                                 <div className='form'>
//                                     <form action="" className='home-form'>
//                                         <h3>log in </h3>
//                                         <input type="text" name="" id="" placeholder='enter your name' className='box1' /><br />
//                                         <input type='number' placeholder='enter your number' className='box1' /><br />
//                                         <button type='submit' className='box1'>Submit</button>
//                                     </form>
//                                 </div>

//                             </div>
//                             <img src={require('../CSS/image/bg5.jpg')} alt="/" />
//                         </div>
//                     </figure>
//                     <figure>
//                         <div className="slide">
//                             <div className='home'>
//                                 <div className='home-text'>
//                                     <h1>
//                                         your home waiting for you..
//                                     </h1>
//                                 </div>
//                                 <div className='form'>
//                                     <form action="" className='home-form'>
//                                         <h3>log in </h3>
//                                         <input type="text" name="" id="" placeholder='enter your name' className='box1' /><br />
//                                         <input type='number' placeholder='enter your number' className='box1' /><br />
//                                         <button type='submit' className='box1'>Submit</button>
//                                     </form>
//                                 </div>

//                             </div>
//                             <img src={require('../CSS/image/bg6.jpg')} alt="/" />
//                         </div>
//                     </figure>
//                 </div> */}