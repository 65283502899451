import React from 'react'
import 'lightgallery/css/lightgallery.css'; 
import LightGallery from 'lightgallery/react/Lightgallery.es5'
import "../App.css";
import "../CSS/HeroProject.css"
// import IMAGES from './IMAGES';
function NobleCallista() {
    return (
        <>
            <div className='project-heading-callista'>
                <p className='hero-heading home-regallia'>noble callista<br />sector 66B,Mohali </p>
            </div>
            <div className="hero-navbar">
                <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
                    <div className="container-fluid">
                        {/* <a className="navbar-brand" href="/">Overview</a> */}
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">OVERVIEW</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">FLOOR-PLAN</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="/">Gallery</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="/">Amenities</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">Location</a>
                                </li>


                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="overview">
                <div className="overview-heading">
                    <h3>overview</h3>
                </div>
                {/* <div className="about-auram"> */}

                    <div className="description ">
                        <p>
                            <span>The Architecture of Callista</span><br />
                            The modern and stylish architecture of Noble Callista attracts you at the very first look. The clean look, incorporating the natural elements as well makes you feel that you have finally arrived home. The serenity of the location, clear architectural lines and panoramic views are a true marvel.<br />Up to 3.5 Acres Open-Green area with parks & fountains
                            3 side open site with 3 exit/entry points.
                            Low-density project with 55 families per acre.
                            3 car parks per apartment (EV & Non-EV).
                            5 Club Houses, 4 mini tower clubs & a Master Club. – Club Royce
                            Half Olympic size swimming pool.</p>

                    </div>
                    {/* <div className="about-img callista-img">
                        <img src={require('../images/noble-callista/NOBEL-AIRIAL-FINAL.jpg')} alt="" />
                    </div> */}

                {/* </div> */}
            </div>

            {/* floor plan section start  */}
            <div className="floor-plan">
                <div className="overview-heading">
                    <h3>floor-plan</h3>
                </div>
                <div className="container-floor-auram">
                    <div className="first-plan-auram">
                        <div>
                            <LightGallery>
                            <a href={require('../images/noble-callista/three-bhk-flat-plan.jpg')}>
                            <img src={require('../images/noble-callista/three-bhk-flat-plan.jpg')} alt="" className='img-floor'/>
                            </a>
                            </LightGallery>
                        </div>
                    </div>
                    <div className="first-plan-auram">
                        <div className='callista-floor'>
                            <LightGallery>
                            <a href={require('../images/noble-callista/four-bhk-flat-plan.jpg')}>
                            <img src={require('../images/noble-callista/four-bhk-flat-plan.jpg')} alt="" className='img-floor' />
                            </a>
                            </LightGallery>
                        </div>
                    </div>
                    <div className="first-plan-auram">
                    <div className='callista-floor'>
                            <LightGallery>
                            <a href={require('../images/noble-callista/five-bhk-flat-plan.jpg')}>
                            <img src={require('../images/noble-callista/five-bhk-flat-plan.jpg')} alt="" className='img-floor' />
                            </a>
                            </LightGallery>
                        </div>
                    </div>
                </div>
            </div>
            {/* floor plan section end  */}

            {/* gallery section start  */}
            <div className="gallery-section">
                <div className="overview-heading">
                    <h3>gallery</h3>
                </div>
                <div className="gallery-plan">
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/noble-callista/1.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-callista/2.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-callista/3.jpg')} alt="" />
                        </div>

                    </div>
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/noble-callista/4.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-callista/5.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-callista/6.jpg')} alt="" />
                        </div>

                    </div>
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/noble-callista/7.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-callista/8.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-callista/9.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-callista/10.jpg')} alt="" />
                        </div>
                    </div>
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/noble-callista/11.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-callista/12.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-callista/13.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-callista/14.jpg')} alt="" />
                        </div>
                    </div>
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/noble-callista/15.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-callista/16.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-callista/17.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-callista/18.jpg')} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* gallery section end  */}

            {/* amenities section start  */}
            <div className="amenities-section">
                <div className="overview-heading">
                    <h3>amenities </h3>
                </div>


                <div className="medallion-amenities">
                    <div className="amenities-first">
                        <div className="amenities-image">
                            <img src={require('../images/the-medallion/lounge.jpg')} alt="" />
                        </div>
                        <div className="amenities-text">
                            <div className="amenities-head">
                                <h3>Master Club & Recreational
                                    Indoor Amenities</h3>
                            </div>
                            <div className="amenities-p">
                                <p>-Reception & Concierge.<br />
                                    -Art & Craft Studio for Kids.<br />
                                    -Chemist shop & First aid room.<br />
                                    -Yoga & Meditation Room.<br />
                                    -Restaurant & Bar.<br />
                                    -Master Banquet - Open & Closed.<br />
                                    -Mini Cineplex.<br />
                                    -Library.<br />
                                    -Cafeteria</p>
                            </div>


                        </div>
                    </div>
                    {/* </div>


        <div className="medallion-amenities"> */}
                    <div className="amenities-first">
                        <div className="amenities-image">
                            <img src={require('../images/the-medallion/kids.jpg')} alt="" />
                        </div>
                        <div className="amenities-text">
                            <div className="amenities-head">
                                <h3>Recreational & Games Area:</h3>
                            </div>
                            <div className="amenities-p">
                                <p>
                                    -(Gym, Yoga, Meditation, <br />-Billiard, Chess,
                                    Carrom, Play Cards etc).<br />
                                    -Kids play area.<br />
                                    -First Aid.<br />
                                    -Sanitization Hub.<br />
                                    -Mini Cineplex</p>
                            </div>


                        </div>
                    </div>
                    <div className="amenities-first">
                        <div className="amenities-image">
                            <img src={require('../images/the-medallion/senior.jpg')} alt="" />
                        </div>
                        <div className="amenities-text">
                            <div className="amenities-head">
                                <h3>Thoughtfully Curated for Senior Residents</h3>
                            </div>
                            <div className="amenities-p">
                                <p>

                                    – Separate Sitouts<br />
                                    – 24×7 Ambulance Service<br />
                                    – On Call Doctor<br />
                                    – 4 Tier Security<br />
                                    – Herb Garden<br />
                                    – Friendly Stair Case</p>
                            </div>


                        </div>
                    </div>
                    <div className="amenities-first">
                        <div className="amenities-image callista-amenities">
                            <img src={require('../images/noble-callista/billiards-room.jpg')} alt="" />
                        </div>
                        <div className="amenities-text">
                            <div className="amenities-head">
                                <h3>Life is a Game</h3>
                            </div>
                            <div className="amenities-p">
                                <p>
                                    – Lawn Tennis Court<br />
                                    – Cricket Pitch<br />
                                    – Basket Ball Court<br />
                                    – Volley Ball Court<br />
                                    -Jacuzzi.<br />
                                    -Steam & Sauna Bath.<br />
                                    -Squash Court.<br />
                                    -Billiards Room.<br />
                                    -Carrom.<br />
                                    -Chess.<br />
                                    -Play Cards.<br />
                                    -Table Tennis</p>
                            </div>


                        </div>
                    </div>
                </div>

            </div>

            {/* amenities section end  */}
            <div className="location-section">
                <div className="overview-heading">
                    <h3>location</h3>
                    <div className="hero-location">
                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3431.088005326632!2d76.69062437465959!3d30.687799887804466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feede075e73a7%3A0x4ea36ee86d384390!2sHero%20Homes%2C%20HERO%20HOMES%2C%20Site%20no.1%2C%20Sector%2088%2C%20Sahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab%20140308!5e0!3m2!1sen!2sin!4v1698322349250!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    </div>
                </div>

            </div>
        </>
    )
}

export default NobleCallista
