import React from 'react'
import 'lightgallery/css/lightgallery.css'; 
import LightGallery from 'lightgallery/react/Lightgallery.es5'
import "../App.css";
import "../CSS/HeroProject.css"
// import IMAGES from './IMAGES';
function MedallionAuram() {
    return (
        <>
            <div className='project-heading-auram'>
                <p className='hero-heading'>The Medallion Aurum -<br /> Sector 67, Mohali</p>
            </div>
            <div className="hero-navbar">
                <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
                    <div className="container-fluid">
                        {/* <a className="navbar-brand" href="/">Overview</a> */}
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">OVERVIEW</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">FLOOR-PLAN</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="/">Gallery</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="/">Amenities</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">Location</a>
                                </li>


                            </ul>
                        </div>
                    </div>
                </nav>
            </div>

            {/* overview section start */}
            <div className="overview">
                <div className="overview-heading">
                    <h3>overview</h3>
                </div>
                {/* <div className="about-auram"> */}
               
                    <div className="description  ">
                        <p><span>PROJECT HIGHLIGHTS</span><br />
                            One of the biggest residential project in Mohali with a truly grand commanding frontage |strategically located on the bustling international airport road | adjoining to the GMADA leisure valley | one of largest club house of the region with world class facilities and amenities| adjoining upcoming commercial project medallion edge with Marriott international | amidst the most thriving neighborhood with the finest options in retail and commercial developments , schools, bustling office hubs, hospitals, colleges and world class recreational options..</p>
                    </div>
                    {/* <div className="about-img ">
                        <img src={require('../images/medallion-auram/about.jpg')} alt="" />
                    </div> */}
                    
                {/* </div> */}
            </div>

                {/* overview section end  */}

                {/* floor plan section start  */}
             <div className="floor-plan">
                <div className="overview-heading">
                    <h3>floor-plan</h3>
                </div>
                <div className="container-floor-auram">
                    <div className="first-plan-auram">  
                        <div>
                        <LightGallery>
                        <a href={require('../images/medallion-auram/f2.jpg')}>
                            <img src={require('../images/medallion-auram/f2.jpg')} alt="" className='img-Auram' />
                            </a>
                            </LightGallery>
                        </div>  
                        <div>
                        <LightGallery>
                        <a href={require('../images/medallion-auram/f4.jpg')}>
                            <img src={require('../images/medallion-auram/f4.jpg')} alt="" className='img-Auram' />
                            </a>
                            </LightGallery>
                        </div>        
                    </div>
                </div>
            </div> 
            {/* floor plan section end  */}

            {/* gallery section start  */}
            <div className="gallery-section">
                <div className="overview-heading">
                    <h3>gallery</h3>
                </div>
                <div className="gallery-plan">
                    <div className="auram-gallery">
                        <div>
                            <img src={require('../images/medallion-auram/1.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/medallion-auram/2.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/medallion-auram/3.jpg')} alt="" />
                        </div>
                        
                    </div>
                    <div className="auram-gallery">
                    <div>
                            <img src={require('../images/medallion-auram/4.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/medallion-auram/5.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/medallion-auram/6.jpg')} alt="" />
                        </div>
                        
                    </div>
                    <div className="auram-gallery">
                    <div>
                            <img src={require('../images/medallion-auram/7.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/medallion-auram/8.jpg')} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* gallery section end  */}

            {/* amenities section start  */}
            
            <div className="amenities-section">
        <div className="overview-heading">
          <h3>amenities </h3>
        </div>
        <div className="amenities-plan">
          <div className="horizon-amenities">
            <div>
              <img src={require('../images/medallion-auram/a1.jpg')} alt="" />
              <p>Gymnasium</p>
            </div>
            <div>
              <img src={require('../images/medallion-auram/a2.jfif')} alt="" />
              <p>cricket training pitch</p>
            </div>
            <div>
              <img src={require('../images/medallion-auram/a3.jfif')} alt="" />
              <p>basement car parking</p>
            </div>
          </div>
          <div className="horizon-amenities">
            
            <div>
              <img src={require('../images/medallion-auram/a4.jpg')} alt="" />
              <p>mini theater </p>
            </div>
            <div>
              <img src={require('../images/medallion-auram/a6.jpg')} alt="" />
              <p>swimming pool</p>
            </div>
            <div>
              <img src={require('../images/medallion-auram/a5.jpg')} alt="" />
              <p>kids play area</p>
            </div>
            
          </div>
          <div className="horizon-amenities">
            <div>
              <img src={require('../images/medallion-auram/a7.jpg')} alt="" />
              <p>cafeteria</p>
            </div>
            <div>
              <img src={require('../images/horizon-belmond/11.jpg')} alt="" />
              <p>Fitness Center</p>
            </div>
            <div>
              <img src={require('../images/medallion-auram/a8.jpg')} alt="" />
              <p>Basketball Court</p>
            </div>
          </div>
          <div className="hero-amenities horizon-amenities">
          <div>
              <img src={require('../images/medallion-auram/a9.jpg')} alt="" />
              <p>vollyball court</p>
            </div>
            <div>
              <img src={require('../images/horizon-belmond/1.jpg')} alt="" />
              <p>CCTV Camera</p>
            </div>
            <div>
              <img src={require('../images/horizon-belmond/4.jpg')} alt="" />
              <p>Yoga Lawn</p>
            </div>
          </div>
        </div>
      </div>


            {/* amenities section end  */}
            <div className="location-section">
                <div className="overview-heading">
                    <h3>location</h3>
                    <div className="hero-location">
                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3431.088005326632!2d76.69062437465959!3d30.687799887804466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feede075e73a7%3A0x4ea36ee86d384390!2sHero%20Homes%2C%20HERO%20HOMES%2C%20Site%20no.1%2C%20Sector%2088%2C%20Sahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab%20140308!5e0!3m2!1sen!2sin!4v1698322349250!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    </div>
                </div>

            </div>
        </>
    )
}

export default MedallionAuram
