import React from 'react'
import 'lightgallery/css/lightgallery.css'; 
import LightGallery from 'lightgallery/react/Lightgallery.es5'
import "../App.css";
import "../CSS/HeroProject.css"
// import IMAGES from './IMAGES';
function FalconView() {
    return (
        <>
            <div className='project-heading-falcon'>
                <p className='hero-heading home-regallia'>falcon view<br />SECTOR 66,Mohali </p>
            </div>
            <div className="hero-navbar">
                <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
                    <div className="container-fluid">
                        {/* <a className="navbar-brand" href="/">Overview</a> */}
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">OVERVIEW</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">FLOOR-PLAN</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="/">Gallery</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="/">Amenities</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">Location</a>
                                </li>


                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="overview">
                <div className="overview-heading">
                    <h3>overview</h3>
                </div>
                {/* <div className="about-auram"> */}

                    <div className="description">
                        <p><span>Falcon View </span><br />
JLPL Group proudly presents the super mega mixed-use integrated industrial park project, featuring the luxurious Falcon View residential component. Spanning an impressive 28.6 acres, this project offers the best 3 & 4 BHK luxury apartments with servant accommodation, as well as best 4 & 5 BHK luxury penthouses with servant accommodation in Mohali. This development is truly one-of-a-kind and will undoubtedly revolutionize the future of the region. With a world class clubhouse and the best amenities such as a putting green inside a mini golf arena, Falcon View is the most coveted address in the area, offering unparalleled luxury and comfort. Falcon View has more than 5000 plantations of different kinds of trees, plants and flowers such as the Ficus Retusa, Ashoka, Bismarck Palm, Copper Bottle Bush, Foxtel Palm, Saraca Indica, Cassia Fistula and many more to keep the complex clean, green and full of fresh air. With its prime location and exceptional amenities, JLPL Falcon View is the perfect place to call home.</p>
                    </div>
                    {/* <div className="about-img falcon-img ">
                        <img src={require('../images/falcon-view/about.jpg')} alt="" />
                    </div> */}

                {/* </div> */}
            </div>

        {/* floor plan section  */}
            <div className="floor-plan">
                <div className="overview-heading">
                    <h3>floor-plan</h3>
                </div>
                <div className="container-floor-auram">
                    <div className="first-plan-noble">
                        <div>
                            <LightGallery>
                            <a href={require('../images/falcon-view/falcon-master-siteplan.jpg')} >
                            <img src={require('../images/falcon-view/falcon-master-siteplan.jpg')} alt="" className='noble-floor img-floor '/>
                            </a>
                            </LightGallery>
                        </div>
                        <div>
                            <LightGallery>
                            <a href={require('../images/falcon-view/3bhk.jpg')}>
                            <img src={require('../images/falcon-view/3bhk.jpg')} alt="" className='noble-floor  img-floor' />
                            </a>
                            </LightGallery>
                        </div>
                        <div>
                            <LightGallery>
                            <a href={require('../images/falcon-view/5bhk.jpg')}>
                            <img src={require('../images/falcon-view/5bhk.jpg')} alt="" className='noble-floor  img-floor'/>
                            </a>
                            </LightGallery>
                        </div>
                    </div>
                </div>
            </div>
            {/* floor plan section end  */}

            {/* gallery section start  */}
            <div className="gallery-section">
                <div className="overview-heading">
                    <h3>gallery</h3>
                </div>
                <div className="gallery-plan">
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/falcon-view/g13.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/falcon-view/g12.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/falcon-view/g11.jpg')} alt="" />
                        </div>

                    </div>
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/falcon-view/g14.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/falcon-view/g10.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/falcon-view/g8.jpg')} alt="" />
                        </div>

                    </div>
                    <div className="homeland-gallery ">
                        <div>
                            <img src={require('../images/falcon-view/g9.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/falcon-view/g2.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/falcon-view/g7.jpg')} alt="" />
                        </div>
                    </div>
                    <div className="homeland-gallery ">
                        <div>
                            <img src={require('../images/falcon-view/g6.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/falcon-view/g1.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/falcon-view/g5.jpg')} alt="" />
                        </div>
                    </div>
                    <div className="homeland-gallery ">
                        <div>
                            <img src={require('../images/falcon-view/g3.jpg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/falcon-view/g4.jpg')} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* gallery section end  */}
            {/* sample flat section start  */}
            {/* <div className="gallery-section">
                <div className="overview-heading">
                    <h3>sample flat</h3>
                </div>
                <div className="gallery-plan">
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/noble-willasa/flat1.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/flat2.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/flat3.jpeg')} alt="" />
                        </div>

                    </div>
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/noble-willasa/flat4.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/flat5.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/flat6.jpeg')} alt="" />
                        </div>

                    </div>
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/noble-willasa/flat7.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/flat8.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/flat9.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/flat10.jpeg')} alt="" />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* sample flat section end  */}

            {/* amenities section start  */}

            <div className="amenities-section">
        <div className="overview-heading">
          <h3>amenities </h3>
        </div>
        <div className="amenities-plan">
          <div className="horizon-amenities">
            <div>
              <img src={require('../images/horizon-belmond/2.jpg')} alt="" />
              <p>Skating Rink</p>
            </div>
            <div>
              <img src={require('../images/falcon-view/golf.jpg')} alt="" />
              <p>Mini Golf</p>
            </div>
            <div>
              <img src={require('../images/medallion-auram/a1.jpg')} alt="" />
              <p>Gymnasium</p>
            </div>
          </div>
          <div className="horizon-amenities">
            
            <div>
              <img src={require('../images/horizon-belmond/6.jpg')} alt="" />
              <p>Jogging Track</p>
            </div>
            <div>
              <img src={require('../images/medallion-auram/a2.jfif')} alt="" />
              <p>box cricket</p>
            </div>
            <div>
              <img src={require('../images/horizon-belmond/3.jpg')} alt="" />
              <p>Swimming Pool</p>
            </div>
            
          </div>
          <div className="horizon-amenities">
            <div>
              <img src={require('../images/horizon-belmond/9.jpg')} alt="" />
              <p>Badminton Court</p>
            </div>
            <div>
              <img src={require('../images/medallion-auram/a7.jpg')} alt="" />
              <p>cafeteria</p>
            </div>
            <div>
              <img src={require('../images/falcon-view/tennis.jpg')} alt="" />
              <p>Basketball Court</p>
            </div>
          </div>
          <div className="hero-amenities horizon-amenities">
          <div>
              <img src={require('../images/falcon-view/amphitheater.jpg')} alt="" />
              <p>Amphi Theater </p>
            </div>
            <div>
              <img src={require('../images/falcon-view/salon.jpg')} alt="" />
              <p>salon</p>
            </div>
            <div>
              <img src={require('../images/horizon-belmond/4.jpg')} alt="" />
              <p>Yoga Lawn</p>
            </div>
          </div>
        </div>
      </div>

            {/* amenities section end  */}
            <div className="location-section">
                <div className="overview-heading">
                    <h3>location</h3>
                    <div className="hero-location">
                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3431.088005326632!2d76.69062437465959!3d30.687799887804466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feede075e73a7%3A0x4ea36ee86d384390!2sHero%20Homes%2C%20HERO%20HOMES%2C%20Site%20no.1%2C%20Sector%2088%2C%20Sahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab%20140308!5e0!3m2!1sen!2sin!4v1698322349250!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    </div>
                </div>

            </div>
        </>
    )
}

export default FalconView
