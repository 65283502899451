import React , {useEffect} from 'react'
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function ContactTcr() {
  useEffect(() => {
    AOS.init({
      duration: 2000, // Animation duration in milliseconds
      once: true,     // Whether animation should happen only once
    });
  }, []);
  return (
    <>
    <div className='contact-container'>
      <div className="project-heading" data-aos='fade-up'>
      <h3>
        Contact US
      </h3>
      <hr />
      </div>
      <div className="contact-div">
      <div className="contact-touch" data-aos='fade-right'>
      <form action="">
        <div className="">
          <div className="heading-touch">
                <h3>Get In Touch</h3>
                <hr className='get-touch'/>
                </div>
                </div>
                <div className="container-form">
                <div className="">
                <input type="text" placeholder="your name" className='form-box' />
                </div>
                <div className="">
                <input type="email" placeholder="email" className='form-box'/>
                </div>
                <div className="">
                <input type="number" placeholder="phone" className='form-box'/>
                </div>
                <div className="">
                <textarea name="your message" id="" cols="48" rows="4" placeholder='your message' className='contact-textarea'></textarea>
                </div>
                <div className="">
                <button type="submit" className="link-btn">Submit</button>
                </div>
                </div>
            </form>
      </div>
      <div className="contact-detail" data-aos='fade-left'>
        <div className="contact-num">
          <p className='contactnum-p'><FontAwesomeIcon icon="fa-solid fa-phone"  className='contact-icon' /> CALL US</p>
          <p>+91 8950801205 , +91 9896219991</p>
        </div>
        <div className="contact-email">
          <p className='contactnum-p'><FontAwesomeIcon icon="fa-regular fa-envelope" className='contact-icon' /> EMAIL</p>
        
          <p>thechandigarhrealtor@gmail.com</p>
          <p>propertytuber@gmail.com</p>
        </div>
      </div>
      </div>
    </div>
    </>
  )
}


