import React from 'react'
import 'lightgallery/css/lightgallery.css'; 
import LightGallery from 'lightgallery/react/Lightgallery.es5'
import "../App.css";
import "../CSS/HeroProject.css"
// import IMAGES from './IMAGES';
function NobleWillasa() {
    return (
        <>
            <div className='project-heading-willasa'>
                <p className='hero-heading home-regallia'>Noble Willasa<br />SECTOR 99,WAVE ESTATE </p>
            </div>
            <div className="hero-navbar">
                <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
                    <div className="container-fluid">
                        {/* <a className="navbar-brand" href="/">Overview</a> */}
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">OVERVIEW</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">FLOOR-PLAN</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="/">Gallery</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="/">Sample FLat</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">Location</a>
                                </li>


                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="overview">
                <div className="overview-heading">
                    <h3>overview</h3>
                </div>
                {/* <div className="about-auram"> */}

                    <div className="description ">
                        <p><span>NOBLE WILLASA
                            Sector 99, Wave Estate, Mohali
                            3BHK LUXURY INDEPENDENT FLOORS</span> <br />Noble Willasa is the first-of-its-kind Luxury Stilt + 4 residential  development located in Wave Estate Sector - 99, Mohali. Featuring designer Italian furnishings, the project shall be a shining example in the application of innovative technologies with the first-time use of Mivan Construction for 3 BHK towers.<br />At Noble Willasa, no detail is overlooked. The fully equipped washrooms feature branded CP and sanitary fittings, along with a looking mirror, a geyser, glass shower enclosures, and a mosaic and exhaust fan. The false ceiling with ambient lighting adds a touch of sophistication to your daily routine</p>
                    </div>
                    {/* <div className="about-img regalia-img">
                        <img src={require('../images/noble-willasa/3.jpeg')} alt="" />
                    </div> */}

                {/* </div> */}
            </div>

        {/* floor plan section  */}
            <div className="floor-plan">
                <div className="overview-heading">
                    <h3>floor-plan</h3>
                </div>
                <div className="container-floor-auram">
                    <div className="first-plan-noble">
                        <div>
                            <LightGallery>
                            <a href={require('../images/noble-willasa/floor-plan4.jpeg')}>
                            <img src={require('../images/noble-willasa/floor-plan4.jpeg')} alt="" className='noble-floor img-floor'/>
                            </a>
                            </LightGallery>
                        </div>
                        <div>
                            <LightGallery>
                            <a href={require('../images/noble-willasa/floor-plan2.jpeg')}>
                            <img src={require('../images/noble-willasa/floor-plan2.jpeg')} alt="" className='noble-floor img-floor' />
                            </a>
                            </LightGallery>
                        </div>
                    </div>
                </div>
            </div>
            {/* floor plan section end  */}

            {/* gallery section start  */}
            <div className="gallery-section">
                <div className="overview-heading">
                    <h3>gallery</h3>
                </div>
                <div className="gallery-plan">
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/noble-willasa/1.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/2.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/3.jpeg')} alt="" />
                        </div>

                    </div>
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/noble-willasa/4.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/5.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/6.jpeg')} alt="" />
                        </div>

                    </div>
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/noble-willasa/7.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/8.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/9.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/10.jpeg')} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* gallery section end  */}
            {/* sample flat section start  */}
            <div className="gallery-section">
                <div className="overview-heading">
                    <h3>sample flat</h3>
                </div>
                <div className="gallery-plan">
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/noble-willasa/flat1.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/flat2.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/flat3.jpeg')} alt="" />
                        </div>

                    </div>
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/noble-willasa/flat4.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/flat5.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/flat6.jpeg')} alt="" />
                        </div>

                    </div>
                    <div className="homeland-gallery">
                        <div>
                            <img src={require('../images/noble-willasa/flat7.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/flat8.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/flat9.jpeg')} alt="" />
                        </div>
                        <div>
                            <img src={require('../images/noble-willasa/flat10.jpeg')} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* sample flat section end  */}

            {/* amenities section start  */}

            <div className="amenities-section">
        <div className="overview-heading">
          <h3>amenities </h3>
        </div>
        <div className="amenities-plan">
          <div className="horizon-amenities">
            <div>
              <img src={require('../images/horizon-belmond/2.jpg')} alt="" />
              <p>Skating Rink</p>
            </div>
            <div>
              <img src={require('../images/medallion-auram/a5.jpg')} alt="" />
              <p>kids play area</p>
            </div>
            <div>
              <img src={require('../images/horizon-belmond/5.jpg')} alt="" />
              <p>Cyber Lounge</p>
            </div>
          </div>
          <div className="horizon-amenities">
            
            <div>
              <img src={require('../images/horizon-belmond/6.jpg')} alt="" />
              <p>Jogging Track</p>
            </div>
            <div>
              <img src={require('../images/medallion-auram/a9.jpg')} alt="" />
              <p>vollyball court</p>
            </div>
            <div>
              <img src={require('../images/horizon-belmond/3.jpg')} alt="" />
              <p>Swimming Pool</p>
            </div>
            
          </div>
          <div className="horizon-amenities">
            <div>
              <img src={require('../images/horizon-belmond/9.jpg')} alt="" />
              <p>Badminton Court</p>
            </div>
            <div>
              <img src={require('../images/horizon-belmond/11.jpg')} alt="" />
              <p>Fitness Center</p>
            </div>
            <div>
              <img src={require('../images/horizon-belmond/7.jpg')} alt="" />
              <p>Basketball Court</p>
            </div>
          </div>
          <div className="hero-amenities horizon-amenities">
          <div>
              <img src={require('../images/medallion-auram/a1.jpg')} alt="" />
              <p>Gymnasium</p>
            </div>
            <div>
              <img src={require('../images/horizon-belmond/1.jpg')} alt="" />
              <p>CCTV Camera</p>
            </div>
            <div>
              <img src={require('../images/horizon-belmond/4.jpg')} alt="" />
              <p>Yoga Lawn</p>
            </div>
          </div>
          <div className="hero-amenities horizon-amenities">
          <div>
              <img src={require('../images/noble-willasa/green.jpg')} alt="" />
              <p>greening area</p>
            </div>
            <div>
              <img src={require('../images/medallion-auram/a7.jpg')} alt="" />
              <p>cafeteria</p>
            </div>
            <div>
              <img src={require('../images/medallion-auram/a2.jfif')} alt="" />
              <p>box cricket</p>
            </div>
          </div>
          
        </div>
      </div>

            {/* amenities section end  */}
            <div className="location-section">
                <div className="overview-heading">
                    <h3>location</h3>
                    <div className="hero-location">
                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3431.088005326632!2d76.69062437465959!3d30.687799887804466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feede075e73a7%3A0x4ea36ee86d384390!2sHero%20Homes%2C%20HERO%20HOMES%2C%20Site%20no.1%2C%20Sector%2088%2C%20Sahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab%20140308!5e0!3m2!1sen!2sin!4v1698322349250!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    </div>
                </div>

            </div>
        </>
    )
}

export default NobleWillasa
