import React from 'react'
import 'lightgallery/css/lightgallery.css'; 
import LightGallery from 'lightgallery/react/Lightgallery.es5'
import "../App.css";
import "../CSS/HeroProject.css"
// import IMAGES from './IMAGES';
function MedallionProject() {
  return (
    <>
      <div className='project-heading-medallion'>
        <p className='hero-heading'>THE medallion<br />sector 82,Mohali</p>
      </div>
      <div className="hero-navbar">
        <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
          <div className="container-fluid">
            {/* <a className="navbar-brand" href="/">Overview</a> */}
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/">OVERVIEW</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/">FLOOR-PLAN</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" href="/">Gallery</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" href="/">Amenities</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/">Location</a>
                </li>


              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className="overview">
        <div className="overview-heading">
          <h3>overview</h3>
        </div>
        <div className="description">
          <p>The Medallion is a premium residential group housing project located in IT City, Sector 82-A, Mohali on 200 ft wide international airport road by Turnstone Realty.

            Offering Luxurious 3 Bhk, 4 Bhk apartments & elegant penthouses. The Medallion offers best in class amenities and infrastructure. Every flat in each tower is a corner flat, designed for ample sunlight and natural ventilation. Every tower has dedicated lobbies with drop off point. The Medallion is the first project in tri-city offering double height podium parking.</p>
        </div>
      </div>
      <div className="floor-plan">
        <div className="overview-heading">
          <h3>floor-plan</h3>
        </div>
        <div className="container-floor">
          <div className="first-plan">
            <div>
              <LightGallery >
                <a href={require('../images/the-medallion/BHK1550.png')} >
              <img src={require('../images/the-medallion/BHK1550.png')} alt="" className='img-floor' />
              </a>
              </LightGallery>
            </div>
            <div>
              <LightGallery >
                <a href={require('../images/the-medallion/3BHK1995.png')} >
              <img src={require('../images/the-medallion/3BHK1995.png')} alt="" className='img-floor'/>
              </a>
              </LightGallery>
            </div>
          </div>
          <div className="first-plan">
          <div>
              <LightGallery >
                <a href={require('../images/the-medallion/4BHK.png')} >
              <img src={require('../images/the-medallion/4BHK.png')} alt="" className='img-floor'/>
              </a>
              </LightGallery>
            </div>
          </div>
        </div>
      </div>
      {/* floor plan section end  */}

      {/* gallery section start  */}
      <div className="gallery-section">
        <div className="overview-heading">
          <h3>gallery</h3>
        </div>
        <div className="gallery-plan">
          <div className="hero-gallery">
            <div>
              <img src={require('../images/the-medallion/medallion_glimpse1-1.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/the-medallion/medallion_glimpse2-1.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/the-medallion/medallion_glimpse4-1.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/the-medallion/medallion_glimpse6-1.jpg')} alt="" />
            </div>
          </div>
          <div className="hero-gallery">
            <div>
              <img src={require('../images/the-medallion/medallion_glimpse7-1.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/the-medallion/medallion_glimpse8-1.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/the-medallion/medallion_glimpse9-1.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/the-medallion/medallion_glimpse10-1.jpg')} alt="" />
            </div>
          </div>
          <div className="hero-gallery">
            <div>
              <img src={require('../images/the-medallion/medallion_glimpse11-1.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/the-medallion/medallion_glimpse12-1.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/the-medallion/main_entrance.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/the-medallion/entrance.jpg')} alt="" />
            </div>
          </div>
          <div className="hero-gallery">
            <div>
              <img src={require('../images/the-medallion/bed_room1.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/the-medallion/living_room1.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/the-medallion/living_room2.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/the-medallion/kitchen.jpg')} alt="" />
            </div>
          </div>
          <div className="hero-gallery">
            <div>
              <img src={require('../images/the-medallion/bed_room4.jpg')} alt="" />
            </div>
            <div>
              <img src={require('../images/the-medallion/bed_room3.jpg')} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* gallery section end  */}

      

      {/* amenities section start  */}
      <div className="amenities-section">
        <div className="overview-heading">
          <h3>amenities </h3>
        </div>


        <div className="medallion-amenities">
          <div className="amenities-first">
            <div className="amenities-image">
              <img src={require('../images/the-medallion/lounge.jpg')} alt="" />
            </div>
            <div className="amenities-text">
              <div className="amenities-head">
                <h3>Club– Celebrating Young Lifestyle</h3>
              </div>
              <div className="amenities-p">
                <p>- Terrace Lounge<br />
                  -Swimming Pool<br />
                  - Spa & Sauna<br />
                  - Gymnasium<br />
                  - Barbeque Areas<br />
                  - Indoor Games Arcade<br />
                  - Restaurant & Coffee Shop<br />
                  - Conference Room<br />
                  - Co-Working Spaces</p>
              </div>


            </div>
          </div>
          {/* </div>


        <div className="medallion-amenities"> */}
          <div className="amenities-first">
            <div className="amenities-image">
              <img src={require('../images/the-medallion/kids.jpg')} alt="" />
            </div>
            <div className="amenities-text">
              <div className="amenities-head">
                <h3>Kids Should Have Fun</h3>
              </div>
              <div className="amenities-p">
                <p>- CCTV Controlled Play Areas<br />
                  - Splash Pool<br />
                  - Gaming Zone<br />
                  - Sand Pits<br />
                  - Art & Craft Studio</p>
              </div>


            </div>
          </div>
          <div className="amenities-first">
            <div className="amenities-image">
              <img src={require('../images/the-medallion/senior.jpg')} alt="" />
            </div>
            <div className="amenities-text">
              <div className="amenities-head">
                <h3>Thoughtfully Curated for Senior Residents</h3>
              </div>
              <div className="amenities-p">
                <p>

                  – Separate Sitouts<br />
                  – 24×7 Ambulance Service<br />
                  – On Call Doctor<br />
                  – 4 Tier Security<br />
                  – Herb Garden<br />
                  – Friendly Stair Case</p>
              </div>


            </div>
          </div>
          <div className="amenities-first">
            <div className="amenities-image">
              <img src={require('../images/the-medallion/life-game.jpg')} alt="" />
            </div>
            <div className="amenities-text">
              <div className="amenities-head">
                <h3>Life is a Game</h3>
              </div>
              <div className="amenities-p">
                <p>
                  – Lawn Tennis Court<br />
                  – Cricket Pitch<br />
                  – Basket Ball Court<br />
                  – Volley Ball Court</p>
              </div>


            </div>
          </div>
        </div>
        
      </div>

      {/* amenities section end  */}
      <div className="location-section">
        <div className="overview-heading">
          <h3>location</h3>
          <div className="hero-location">
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3431.088005326632!2d76.69062437465959!3d30.687799887804466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feede075e73a7%3A0x4ea36ee86d384390!2sHero%20Homes%2C%20HERO%20HOMES%2C%20Site%20no.1%2C%20Sector%2088%2C%20Sahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab%20140308!5e0!3m2!1sen!2sin!4v1698322349250!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
          </div>
        </div>

      </div>
    </>
  )
}

export default MedallionProject
